import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable, BehaviorSubject, of } from "rxjs";
import {
  switchMap,
  filter,
  take,
  tap,
  catchError,
  finalize
} from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthenticationService } from "./core/services/authentication.service";
import { Config } from "./core/data/config";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AuthHttpInteceptor implements HttpInterceptor {
  private helper = new JwtHelperService();
  private refreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _config: Config,
    private _http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get("Authorization")) {
      if (this.helper.isTokenExpired(request.headers.get("Authorization"), 5)) {
        if (!this.refreshingToken) {
          this.refreshingToken = true;
          this.tokenSubject.next(null);
          let adminToken = "";
          if (localStorage.getItem("admin")) {
            adminToken = localStorage.getItem("admin");
          }
          let headers = new HttpHeaders({
            Authorization: localStorage.getItem("refreshToken"),
            "x-service-type": adminToken
          });
          let options = { headers: headers };
          return this._http
            .post<any>(
              this._config.apiBaseUrl + "web_refresh_token",
              { ad: "sad" },
              options
            )
            .pipe(
              switchMap(data => {
                if (data.status == 2) {
                  this._authService.loggedOut();
                  this._config.showMessage(
                    "Your session has been expired. Please login again."
                  );
                  this._router.navigate([""]);
                } else {
                  if (data.status == 1) {
                    localStorage.setItem("token", data.token);
                    this.tokenSubject.next(data.token);
                    return next
                      .handle(
                        request.clone({
                          setHeaders: {
                            Authorization: localStorage.getItem("token")
                          }
                        })
                      )
                      .pipe(
                        tap(res => {
                          return res;
                        })
                      );
                  }
                }
              }),
              finalize(() => {
                this.refreshingToken = false;
              })
            );
        } else {
          return this.tokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
              return next
                .handle(
                  request.clone({
                    setHeaders: {
                      Authorization: localStorage.getItem("token")
                    }
                  })
                )
                .pipe(
                  tap(res => {
                    //@ts-ignore
                    if (res.status == 2) {
                      this.loggedOut();
                    }
                    if (res instanceof HttpErrorResponse) {
                      this.loggedOut();
                    }
                    return res;
                  }),
                  catchError((err: any) => {
                    if (err instanceof HttpErrorResponse) {
                      this.loggedOut();
                    }
                    return of(err);
                  })
                );
            })
          );
        }
      } else {
        return next.handle(request).pipe(
          tap(res => {
            if (res instanceof HttpResponse) {
              if (res.body) {
                //@ts-ignore
                if (res.body.status == 2) {
                  this.loggedOut();
                }
              }
            }
            return res;
          }),
          catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.loggedOut();
            }
            return of(err);
          })
        );
      }
    } else {
      return next.handle(request);
      // .pipe(
      //   tap(res => {
      //     return res;
      //   }),
      //   catchError((err: any) => {
      //     if (err instanceof HttpErrorResponse) {
      //       this.loggedOut();
      //     }
      //     return of(err);
      //   })
      // );
    }
  }

  loggedOut() {
    this._authService.loggedOut();
  }
}
