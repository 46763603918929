import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  defferedPrompt;
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.matchMedia("(display-mode:standalone)").matches) {
        document.getElementById("pwa-added").style.display = "none";
      }

      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        this.defferedPrompt = e;
      });
    }
  }

  addToHome() {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener("beforeinstallprompt", e => {
        this.defferedPrompt = e;
        setTimeout(function() {
          this.deferredPrompt.prompt();
        }, 20000);
      });
    }

    this.defferedPrompt.prompt();
    this.defferedPrompt.userChoice.then(result => {
      if (isPlatformBrowser(this.platformId)) {
        if (result.outcome === "accepted") {
          document.getElementById("added").style.display = "none";
        }
      }

      //this.defferedPrompt = null;
    });
  }
}
