import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class HttpCancelService {
  private cancelPendingRequest$ = new Subject<void>();

  constructor() {}

  public cancelPendingRequests() {
    this.cancelPendingRequest$.next();
  }

  public onCancelPendingRequest() {
    return this.cancelPendingRequest$.asObservable();
  }
}
