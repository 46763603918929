import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-from-alldevices',
  templateUrl: './logout-from-alldevices.component.html',
  styleUrls: ['./logout-from-alldevices.component.scss']
})
export class LogoutFromAlldevicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
