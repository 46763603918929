import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Config } from "../data/config";
import { DataExchangeService } from "./data-exchange.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { TranslateService } from "@ngx-translate/core";
import {environment} from '../../../environments/environment'
@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  adminUrl = environment.admin_url;
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _config: Config,
    private _dataExchangeService: DataExchangeService,
    private _translate: TranslateService
  ) { }

  private helper = new JwtHelperService();

  /** All urls related to Authentication's Apis */

  private loginUserUrl = this._config.apiBaseUrl + "web_login";
  private refreshTokenUrl = this._config.apiBaseUrl + "web_refresh_token";
  private deleteRefreshTokenUrl =
    this._config.apiBaseUrl + "admin/delete-token";
  private _getUserConnectionDetailUrl =
    this._config.apiBaseUrl + "web_get_user_connection_details";
  private logoutFromAllDeviceUrl =
    this._config.apiBaseUrl + "web_logout_from_all_device";
  private resetPasswordUrl = this._config.apiBaseUrl + "web_reset_password";

  /** Call the User login api */

  loginUser(user) {
    return this._http.post<any>(this.loginUserUrl, user);
  }

  /** Calls the Admin login api */

  loginAdmin(user) {
    return this._http.post<any>(this.loginUserUrl, user);
  }

  /** Checking for JWT in localstorage for checking user is logged in or not */

  loggedIn() {
    return !!localStorage.getItem("token");
  }

  /** Calls api for getting user connection detail */

  getUserConnection() {
    let options = this._config.getHeader();
    return this._http.post(this._getUserConnectionDetailUrl, {}, options);
  }

  /** Calls api for getting new access Token By providing refresh token stored in localstrorage */

  getNewAccessToken() {
    let data = { token: localStorage.getItem("refreshToken") };
    let headers = new HttpHeaders({
      Authorization: localStorage.getItem("refreshToken")
    });
    let options = { headers: headers };
    return this._http.post<any>(this.refreshTokenUrl, data, options);
  }

  /* Return the JWT Token from local storage of client */

  getToken() {
    return localStorage.getItem("token");
  }

  /** Reset the Password of user */

  resetPassword(data, token) {
    let headers = new HttpHeaders({
      Authorization: token
    });
    let options = { headers: headers };
    return this._http.post<any>(this.resetPasswordUrl, data, options);
  }

  /* Remove token from client's local storage when user logged out */

  async loggedOut() {
    if (localStorage.getItem("token")) {
      if(localStorage.getItem("admin")){
        this._router.navigate([`/${this.adminUrl}`]);
      }
      else{
        this._router.navigate([""]);
      }
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
      localStorage.removeItem("user_id");
      this._dataExchangeService.changeLogoutStatus("logout");
      localStorage.clear();
    }
  }

  LogoutFromAllDevices() {
    let options = this._config.getHeader();
    return this._http.post<any>(this.logoutFromAllDeviceUrl, {}, options);
  }

  /** Calls api for authorization of user */

  async verifyUser(authType, menu_id) {
    try {
      let data = this.helper.decodeToken(localStorage.getItem("token")).data;
      if (data) {
        if (data.user_id == undefined || data.user_id == null) {
          this.loggedOut();
        } else {
          if (data.user_id > 0 && menu_id < 9) {
            this.loggedOut();
          } else {
            if (data.user_id == 0 && menu_id > 9) {
              this.loggedOut();
            } else {
              if (!this._config.mqttConnection) {
                this.getUserConnection().subscribe(
                  res => {
                    //@ts-ignore
                    if (res.status == 1) {
                      if (data.user_id == 0) {
                        this._dataExchangeService.changeMqtt(
                          //@ts-ignore
                          JSON.stringify(res.user_pv_details)
                        );
                        this._dataExchangeService.changeLoginStatus("admin");
                      } else {
                        if (menu_id < 8) {
                          this.loggedOut();
                        }
                        //@ts-ignore
                        res.user_pv_details[0].email = "ds";
                        this._dataExchangeService.changeMqtt(
                          //@ts-ignore
                          JSON.stringify(res.user_pv_details[0])
                        );
                        localStorage.setItem(
                          "email",
                          //@ts-ignore
                          res.user_pv_details[0].key_un
                        );
                        this._dataExchangeService.changeLoginStatus(
                          //@ts-ignore
                          "user" + res.user_pv_details[0].user_full_name
                        );
                      }
                    } else {
                      this._translate
                        .get("SessionExpired")
                        .subscribe(message => {
                          this._config.showMessage(message);
                        });
                      this._router.navigate([""]);
                    }
                  },
                  err => {
                    this._translate.get("SessionExpired").subscribe(message => {
                      this._config.showMessage(message);
                    });
                    this._router.navigate([""]);
                  }
                );
              }
            }
          }
        }
      } else {
        this.loggedOut();
      }
    } catch {
      this.loggedOut();
    }
  }
}
