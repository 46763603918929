import {HttpHeaders} from '@angular/common/http';
import {IMqttServiceOptions, MqttService} from 'ngx-mqtt';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material';
import {Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

export class Config {
  constructor(
    @Inject(MatSnackBar) private _message: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  private WebDomain: string = "";
  public domainName = window.location.host;
  public apiHostMap = {
    "web.gotailwind.com": "https://connect.gotailwind.com",
    "web.test.gotailwind.com": "https://connect.test.gotailwind.com",
    "web.prod.gotailwind.com": "https://connect.prod.gotailwind.com",
    "localhost:4200": "http://localhost",
  }
  public defaultHost = "https://connect.gotailwind.com"
  //  || "localhost:4200"
  public apiBaseUrl: string = this.getBaseApiUrl()

  private mqttPort = 8084;
  public imageBaseUrl: string = "assets/";
  public iconBaseUrl: string = "assets/icons/";
  public _mqttService: MqttService;
  public horizontalPlace: MatSnackBarHorizontalPosition = "right";
  public verticalPlace: MatSnackBarVerticalPosition = "top";
  public _development: boolean = true;
  public mqttConnection: boolean = false;
  public topics = {
    status: "status_initial",
    details: "details",
    door_status: "status",
    command: "command",
  };

  public publish = {
    command: "command",
  };

  /**
   * It will display the snackbar to the User.
   * @param data Message for displaying the SnackBar
   */

  showMessage(data) {
    let second = (data.length * 1000) / 13;
    this._message.open(data, "dismiss", {
      duration: second,
      horizontalPosition: this.horizontalPlace,
      verticalPosition: this.verticalPlace,
    });
  }

  setSideBar() {
    document.getElementById("sidebar").style.display = "block";
    document.getElementById("router-content").style.marginLeft = "210px";
  }

  /**
   * It will create MQTT Service Option for connection of the Admin.
   * @param data Credential for Admin MQTT Connection Option
   */

  getAdminOptions(data) {

    let MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
      hostname: "mqtt-wss.gotailwind.com",
      path: "/mqtt",
      port: this.mqttPort,
      clientId: "parth_admin" + new Date().getTime().toString(),
      username: data.key_un,
      password: data.id,
      protocol: "wss",
    };
    return MQTT_SERVICE_OPTIONS;
  }

  /**
   * It will create MQTT Service Option for connection of the User.
   * @param data Credential for User MQTT Connection Option
   */

  getUserConnection(data) {
    let MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
      hostname: "mqtt-wss.gotailwind.com",
      path: "/mqtt",
      port: this.mqttPort,
      clientId: data.id + new Date().getTime().toString(),
      username: data.key_un,
      password: data.id,
      protocol: "wss",
    };
    return MQTT_SERVICE_OPTIONS;
  }

  /**
   * It will add JWT token stored in LocalStorage.
   * It will generate the Header and return it.
   */

  getHeader(): any {
    if (isPlatformBrowser(this.platformId)) {
      let headers = new HttpHeaders({
        Authorization: localStorage.getItem("token"),
      });
      let options = { headers: headers };
      return options;
    }
  }

  getBaseApiUrl(): any {
    return (this.apiHostMap[this.domainName] ? this.apiHostMap[this.domainName] : this.defaultHost) + "/garage_app_ws/WebPortalService.php?Service=";
  }

  getOauthHost(): any {
    return this.apiHostMap[this.domainName] ? this.apiHostMap[this.domainName] : this.defaultHost;
  }

  getLogHost(): any {
    return this.apiHostMap[this.domainName] ? this.apiHostMap[this.domainName] : this.defaultHost;
  }
}
