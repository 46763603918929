import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DataExchangeService {
  /** Service for data interaction among components */

  constructor() {}

  private loginMessage = new Subject<string>();
  private modules = new Subject<string>();
  private logoutMessage = new Subject<string>();
  private setMqttMessage = new Subject<string>();
  private disconnectMqttMessage = new Subject<string>();
  private displayProgress = new Subject<boolean>();

  loginMessage$ = this.loginMessage.asObservable();
  modules$ = this.modules.asObservable();
  logoutMessage$ = this.logoutMessage.asObservable();
  setMqtt$ = this.setMqttMessage.asObservable();
  disconnectMqtt$ = this.disconnectMqttMessage.asObservable();
  displayProgess$ = this.displayProgress.asObservable();

  /** Functions for changing observable's data */

  changeLoginStatus(login) {
    this.loginMessage.next(login);
  }

  changeModules(modulesData) {
    this.modules.next(modulesData);
  }

  changeLogoutStatus(status) {
    this.logoutMessage.next(status);
  }

  changeMqtt(mqttdata) {
    this.setMqttMessage.next(mqttdata);
  }

  changeProgress(status) {
    this.displayProgress.next(status);
  }
}
