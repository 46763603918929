import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatDialogModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatInputModule,
  MatDividerModule,
  MatListModule,
  MatMenuModule,
  MatToolbarModule,
  MatRippleModule,
  MatButtonModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatGridListModule,
  MatTabsModule,
  MatProgressBarModule,
  MatChipsModule,
  MatRadioModule
} from "@angular/material";
import { LogoutFromAlldevicesComponent } from "./logout-from-alldevices/logout-from-alldevices.component";
import { ConfirmDialog } from "../pages/admin/device/device-warranty/device-warranty.component";
import { GetKeyComponent } from "../pages/client/integration/get-key/get-key.component";
import { FormsModule } from "@angular/forms";
import { FooterComponent } from "../theme/footer/footer.component";
import { doorOpenDialog } from "../pages/client/devices/my-device/my-device.component";
import { TranslateModule } from "@ngx-translate/core";
import { ConfirmOptionComponent } from "./confirm-option/confirm-option.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { FreeTextSearchDialogComponent } from "./free-text-search-dialog/free-text-search-dialog.component";
import { InfoDialogComponent } from "./info-dialog/info-dialog.component";

const material = [
  MatButtonModule,
  MatCheckboxModule,
  MatRippleModule,
  MatIconModule,
  MatToolbarModule,
  MatMenuModule,
  MatListModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatGridListModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatDialogModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatTabsModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  NgxMaterialTimepickerModule,
  MatChipsModule,
  MatRadioModule
];

@NgModule({
  declarations: [
    LogoutFromAlldevicesComponent,
    ConfirmDialog,
    GetKeyComponent,
    FooterComponent,
    doorOpenDialog,
    ConfirmOptionComponent,
    FreeTextSearchDialogComponent,
    InfoDialogComponent
  ],
  imports: [CommonModule, material, FormsModule, TranslateModule],
  exports: [
    material,
    LogoutFromAlldevicesComponent,
    GetKeyComponent,
    FooterComponent,
    ConfirmDialog,
    doorOpenDialog,
    ConfirmOptionComponent,
    FreeTextSearchDialogComponent,
    InfoDialogComponent
  ],
  entryComponents: [ConfirmDialog, doorOpenDialog]
})
export class SharedModule {}
