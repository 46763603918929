import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { Inject } from "@nestjs/common";
import { UserService } from "src/app/core/services/user.service";
import { Config } from "src/app/core/data/config";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-free-text-search-dialog",
  templateUrl: "./free-text-search-dialog.component.html",
  styleUrls: ["./free-text-search-dialog.component.scss"]
})
export class FreeTextSearchDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FreeTextSearchDialogComponent>,
    private _userService: UserService,
    private _config: Config,
    private _translateService: TranslateService
  ) {}
  public searchValue: string = "";
  public users: any = new Array();
  public displayMessage = "";
  ngOnInit() {}

  getUserList() {
    if (this.searchValue.length < 3) {
      return;
    }
    document.getElementById("spinner").style.display = "block";
    document.getElementById("content").style.display = "none";
    this._userService.searchUser({ search_text: this.searchValue }).subscribe(
      res => {
        this.users=[];
        console.log("user list",res);
        document.getElementById("spinner").style.display = "none";
        document.getElementById("content").style.display = "block";
        //@ts-ignore
        if (res.status == 1) {
          //@ts-ignore
          this.users = res.users;
          if (this.users.length < 1) {
            this._translateService.get("NoDataFound").subscribe(message => {
              this._config.showMessage(message);
              this.displayMessage = message;
            });
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].user_full_name = this.users[i].user_full_name.replace(
              new RegExp(this.searchValue, "ig"),
              `<span class="highlightText">${this.searchValue}</span>`
            );
            this.users[i].email = this.users[i].email.replace(
              new RegExp(this.searchValue, "ig"),
              `<span class="highlightText">${this.searchValue}</span>`
            );
          }
          var sheet = document.createElement("style");
          sheet.innerHTML =
            ".highlightText { background-color: #fcbb07 !important;}";
          document.body.appendChild(sheet);
        } else {
          //@ts-ignore
          this._config.showMessage(res.message);
        }
      },
      err => {
        
        document.getElementById("spinner").style.display = "none";
        this._translateService.get("NoResponseFound").subscribe(message => {
          this._config.showMessage(message);
        });
      }
    );
  }

  getUserDetail(user) {
    this.dialogRef.close(user);
  }
}
