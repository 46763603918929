import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-confirm-option",
  templateUrl: "./confirm-option.component.html",
  styleUrls: [
    "./confirm-option.component.scss",
    "../../pages/admin/device/device-warranty/ok-cancel.scss"
  ]
})
export class ConfirmOptionComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  public confirm: boolean = false;
  ngOnInit() {}

  /**
   * It will close the dialog
   * @param result boolean value for ok or cancel
   */

  closeDialog(result) {
    this.dialogRef.close({ logout: result, all: this.confirm });
  }
}
