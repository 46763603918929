import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { IntegrationService } from "src/app/core/services/integration.service";
import { TranslateService } from "@ngx-translate/core";
import { Config } from "src/app/core/data/config";
import { isPlatformBrowser } from "@angular/common";
import { InteractionService } from "src/app/core/services/interaction.service";
import { DataExchangeService } from "src/app/core/services/data-exchange.service";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { InfoDialogComponent } from "src/app/shared/info-dialog/info-dialog.component";

@Component({
  selector: "app-get-key",
  templateUrl: "./get-key.component.html",
  styleUrls: ["./get-key.component.scss"]
})
export class GetKeyComponent implements OnInit {
  public is_verified = false;
  data = {
    company_name: "",
    redirect_url: "",
    live_update_url: "",
    is_public: 2
  };
  credential = {
    client_id: null,
    client_secret: null,
    redirect_uri: null,
    live_update_url: null,
    scope: null
  };
  public integration_detail = new Array();
  public display_form = false;
  public authorizationUrl = "";
  public privateHint = "";
  public publicHint = "";
  constructor(
    private _integrationService: IntegrationService,
    private _translate: TranslateService,
    private _config: Config,
    private _interactionService: InteractionService,
    private _dataExchangeService: DataExchangeService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  /**
   * It will get the Integration detail of the User.
   * If integration detail found, it will display it otherwise it will open add integration form.
   */

  async ngOnInit() {
    this._translate.get("PublicIntegrationHint").subscribe(message => {
      this.publicHint = message;
    });
    this._translate.get("PrivateIntegrationHint").subscribe(message => {
      this.privateHint = message;
    });
    this._interactionService.changeMenu(12);
    if (isPlatformBrowser(this.platformId)) {
      if (window.screen.width < 800) {
        document.getElementById("card").style.width =
          (window.screen.width - 10).toString() + "px";
      }
    }
    this._dataExchangeService.changeProgress(true);
    this._integrationService.getIntegrationDetail().subscribe(
      res => {
        this._dataExchangeService.changeProgress(false);
        //@ts-ignore
        if (res.status == 1) {
          //@ts-ignore
          if (res.all_integrations.length < 1) {
            this.display_form = true;
          } else {
            //@ts-ignore
            this.integration_detail = res.all_integrations;
            this.authorizationUrl = this._config.getOauthHost() +
              "/oauth/integration2/authorize.php?response_type=code&state=" +
              this.integration_detail[0].scope +
              "&client_id=" +
              this.integration_detail[0].client_id +
              "&scope=" +
              this.integration_detail[0].scope +
              "&redirect_uri=" +
              this.integration_detail[0].redirect_uri;
          }
        }
      },
      err => {
        this._dataExchangeService.changeProgress(false);
      }
    );
  }

  /** Generate the integration key if url provided by client is unique. */

  generateKey() {
    this.data.company_name = this.data.company_name.trim();
    this.data.redirect_url = this.data.redirect_url.trim();
    this.data.live_update_url = this.data.live_update_url.trim();
    let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById("result").style.display = "none";
      if (this.data.company_name.length >= 2) {
        if (this.data.company_name.length <= 20) {
          if (pattern.test(this.data.redirect_url)) {
            if (pattern.test(this.data.live_update_url)) {
              if (
                this.data.redirect_url.length < 2048 ||
                this.data.live_update_url.length < 2048
              ) {
                if (this.data.is_public < 2 && this.data.is_public > -1) {
                  console.log(this.data);
                  this._dataExchangeService.changeProgress(true);
                  this._integrationService.generateKey(this.data).subscribe(
                    res => {
                      this._dataExchangeService.changeProgress(false);
                      //@ts-ignore
                      if (res.status === 1) {
                        //@ts-ignore
                        this.credential.client_id = res.integration_details.id;

                        this.credential.client_secret =
                          //@ts-ignore
                          res.integration_details.secret;
                        //@ts-ignore
                        this.credential.redirect_uri = this.data.redirect_url;
                        //@ts-ignore
                        this.credential.scope = res.integration_details.scope;
                        this.credential.live_update_url = this.data.live_update_url;
                        this.integration_detail.push(this.credential);

                        this.display_form = false;
                        this._translate
                          .get("SuccessfullIntegration")
                          .subscribe(message => {
                            this._config.showMessage(message);
                          });
                        this.authorizationUrl = this._config.getOauthHost()
                          "/oauth/integration2/authorize.php?response_type=code&state=" +
                          this.credential.scope +
                          "&client_id=" +
                          this.credential.client_id +
                          "&scope=" +
                          this.credential.scope +
                          "&redirect_uri=" +
                          this.credential.redirect_uri;
                      } else {
                        document.getElementById("result").style.display =
                          "none";
                        //@ts-ignore
                        this._config.showMessage(res.message);
                      }
                    },
                    err => {
                      this._dataExchangeService.changeProgress(false);
                      this._translate.get("NoResponseFound").subscribe(data => {
                        this._config.showMessage(data);
                      });
                    }
                  );
                } else {
                  this._translate
                    .get("SelectIntegrationType")
                    .subscribe(message => {
                      this._config.showMessage(message);
                    });
                }
              } else {
                this._translate.get("URLLimit").subscribe(message => {
                  this._config.showMessage(message);
                });
              }
            } else {
              this._translate
                .get("ValidResponseRedirectUrl")
                .subscribe(data => {
                  this._config.showMessage(data);
                });
            }
          } else {
            this._translate.get("ValidRedirectUrl").subscribe(data => {
              this._config.showMessage(data);
            });
          }
        } else {
          this._translate.get("MaxValidCompanyName").subscribe(data => {
            this._config.showMessage(data);
          });
        }
      } else {
        this._translate.get("MinValidCompanyName").subscribe(data => {
          this._config.showMessage(data);
        });
      }
    }
  }

  /** Copy the client secret and client password of the integration. */

  copyText(cmd) {
    let selBox;
    if (isPlatformBrowser(this.platformId)) {
      selBox = document.createElement("textarea");
    }

    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = cmd;

    if (isPlatformBrowser(this.platformId)) {
      document.body.appendChild(selBox);
    }

    selBox.focus();
    selBox.select();
    if (isPlatformBrowser(this.platformId)) {
      document.execCommand("copy");
      document.body.removeChild(selBox);
    }
    this._translate.get("AuthorizationUrlCopied").subscribe(message => {
      this._config.showMessage(message);
    });
  }

  /** It will verify the user's redirectUrl */

  verifyRedirectUrl() {
    let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (pattern.test(this.data.redirect_url)) {
      let url = this.data.redirect_url + "?code=8670";
      this._http.get<any>(url).subscribe(
        res => {
          if (res.toString() == "8670") {
            this.is_verified = true;
          } else {
            this._translate.get("RedirectUrlNotVerified").subscribe(message => {
              this._config.showMessage(message);
            });
          }
        },
        err => {}
      );
    } else {
      this._translate.get("ValidRedirectUrl").subscribe(data => {
        this._config.showMessage(data);
      });
    }
  }

  getHelp(message) {
    this._translate.get(message).subscribe(messageData => {
      this._dialog.open(InfoDialogComponent, {
        data: { message: messageData }
      });
    });
  }
}
