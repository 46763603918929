import { Injectable } from "@angular/core";
import { Config } from "../data/config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class BeaconConfigService {
  constructor(private _config: Config, private _http: HttpClient) {}

  private service: string = "beacon-config/";

  /** Call api's urls related to Beacon_config */

  private getVehicleSensorDetailUrl =
    this._config.apiBaseUrl + this.service + "vehicle-sensor-data/";
  private notificationSettingUrl =
    this._config.apiBaseUrl + "web_get_user_app_setting";

  /** Calls api for getting vehicle sensor detail */

  getVehicleSensorDetail(device_id) {
    let options = this._config.getHeader();
    return this._http.get<any>(
      this.getVehicleSensorDetailUrl + device_id,
      options
    );
  }

  /** Calls api for getting the notification setting detail */

  getNoficationSettings(data) {
    let options = this._config.getHeader();
    return this._http.post<any>(this.notificationSettingUrl, data, options);
  }
}
