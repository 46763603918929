import { Component, OnInit, PLATFORM_ID, Inject } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { isPlatformBrowser } from "@angular/common";
import { Config } from "./core/data/config";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivationEnd } from "@angular/router";
import { HttpCancelService } from "./core/services/http-cancel.service";
import { DataExchangeService } from "./core/services/data-exchange.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "Tailwind";
  location: Location;
  defferedPrompt;
  constructor(
    private _config: Config,
    private translate: TranslateService,
    private _router: Router,
    private _dataExchangeService: DataExchangeService,
    private _httpCancelRequsetService: HttpCancelService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private updates: SwUpdate
  ) {
    translate.setDefaultLang("en");
    updates.available.subscribe(event => {
      if (true) {
        updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }
  // constructor(private updates: SwUpdate) {

  // }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.screen.width < 1024) {
        this._config.horizontalPlace = "center";
        this._config.verticalPlace = "bottom";
      }
    }

    // this._router.events.subscribe(event => {
    //   if (event instanceof ActivationEnd) {
    //     this._httpCancelRequsetService.cancelPendingRequests();
    //     this._dataExchangeService.changeProgress(false);
    //   }
    // });
  }

  changeLanguage(language) {
    this.translate.use(language);
  }
}
