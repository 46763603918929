import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Config } from "../data/config";

@Injectable({
  providedIn: "root"
})
export class IntegrationService {
  service = "integration/";

  constructor(private _http: HttpClient, private _config: Config) {}

  /** All url related to Integration with third party controllers */

  private generateKeyUrl =
    this._config.apiBaseUrl + "web_crud_user_own_integrations";
  private getLocalUrl =
    this._config.apiBaseUrl + "app_get_local_control_pin";
  private integrationDetailUrl =
    this._config.apiBaseUrl + "web_get_all_user_integrations";
  private changeStatusUrl =
    this._config.apiBaseUrl + "web_enable_disable_user_integrations";
  private _getUserIntegrationUrl =
    this._config.apiBaseUrl + this.service + "user-integration-detail";
  private _updateIntegrationDetailUrl =
    this._config.apiBaseUrl + this.service + "update-integration-detail";

  /** It will call api for generating client Id and secret from server */

  generateKey(data) {
    let options = this._config.getHeader();
    return this._http.post(this.generateKeyUrl, data, options);
  }

    /** It will call api for generating client Id and secret from server */

    getInsertUpdateToken(data:any):any {
      let options = this._config.getHeader();
      return this._http.post(this.getLocalUrl, data, options);
    }
  

  /** It will get the details of all integration */

  getIntegrationDetail() {
    let options = this._config.getHeader();
    return this._http.post<any>(this.integrationDetailUrl, {}, options);
  }

  /** It will call api for enabling new user inserted integration by changing status of it */

  changeIntegrationStatus(data) {
    let options = this._config.getHeader();
    return this._http.put(this.changeStatusUrl, data, options);
  }

  /** It will call api for getting the integration data of the user. */

  getUserIntegration() {
    let options = this._config.getHeader();
    return this._http.get<any>(this._getUserIntegrationUrl, options);
  }

  /** It will update the integration detail url of user */

  updateIntegrationDetail(data) {
    let options = this._config.getHeader();
    return this._http.put<any>(this._updateIntegrationDetailUrl, data, options);
  }
}
