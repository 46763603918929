import {
  Component,
  OnInit,
  ViewChild,
  PLATFORM_ID,
  Inject
} from "@angular/core";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material";
import { DeviceService } from "src/app/core/services/device.service";
import { WarrantyData } from "src/app/core/data/warranty-data";
import { Config } from "src/app/core/data/config";
import { TranslateService } from "@ngx-translate/core";
import { InteractionService } from "src/app/core/services/interaction.service";
import { DataExchangeService } from "src/app/core/services/data-exchange.service";

@Component({
  selector: "app-device-warranty",
  templateUrl: "./device-warranty.component.html",
  styleUrls: ["./device-warranty.component.scss"]
})
export class DeviceWarrantyComponent implements OnInit {
  device_warranty: number = null;
  warrantyData: WarrantyData[] = new Array();
  AllData: WarrantyData[] = new Array();
  lastProvidedMonth;
  dataSource;
  currentPage = 1;
  totalPages = 1;
  filter = "";
  displayedColumns: string[] = [
    "no",
    "device_warranty_device_id",
    "remain_warranty",
    "device_warranty_modified_date",
    "user_email",
    "device_warranty_warranty",
    "action"
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _deviceService: DeviceService,
    public confirmDialog: MatDialog,
    private _config: Config,
    private _translate: TranslateService,
    private _interactionService: InteractionService,
    private _dataExchangeService: DataExchangeService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      document.title = "Admin Dashboard | Tailwind";
    }
  }

  ngOnInit() {
    this._interactionService.changeMenu(4);
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem("token")) {
        this._router.navigate([""]);
      } else {
        this._authService.verifyUser("admin", 4);
      }
    }
  }

  /**
   * It will filter the datasource of the Device Warranty.
   * @param filterValue String which user want to filter.
   */

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** It will filter the cards for mobile viewer. */

  filterCard() {
    this.dataSource.filter = this.filter.trim().toLowerCase();
    this.AllData = this.dataSource.filteredData;
    this.totalPages = this.AllData.length / 10;
    this.totalPages = Math.ceil(this.totalPages);
    this.warrantyData = new Array();

    if (this.AllData.length > 0) {
      for (let i = 0; i < 10; i++) {
        if (i < this.AllData.length) {
          this.warrantyData.push(this.AllData[i]);
        } else {
          break;
        }
      }
      this.currentPage = 1;
    } else {
      this.warrantyData = new Array();
      this.currentPage = 0;
    }
  }

  /** It will get the warranty detail of provided months. */

  getDeviceWarranty() {
    if (isNaN(this.device_warranty)) {
      this._translate.get("MonthInNumber").subscribe(data => {
        this._config.showMessage(data);
      });
    } else {
      this.lastProvidedMonth = this.device_warranty;
      this._dataExchangeService.changeProgress(true);
      this._deviceService
        .getDeviceWarrantyDetail(this.lastProvidedMonth, true)
        .subscribe(
          res => {
            this._dataExchangeService.changeProgress(false);
            //@ts-ignore
            if (res.status == 1) {
              this.warrantyData = new Array();
              //@ts-ignore
              let data = res.tot_device_warranty_period_remain;
              if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                  let days;
                  if (this.lastProvidedMonth > 1) {
                    days = this.lastProvidedMonth;
                  } else {
                    let warrantydate = new Date(data[i].modified_date);
                    if (warrantydate.getMonth() + data[i].warranty > 12) {
                      warrantydate.setFullYear(
                        warrantydate.getFullYear() +
                          Math.floor(data[i].warranty / 12)
                      );
                      warrantydate.setMonth(
                        warrantydate.getMonth() + (data[i].warranty % 12)
                      );
                    } else {
                      warrantydate.setMonth(
                        warrantydate.getMonth() + data[i].warranty
                      );
                    }
                    days = Math.floor(
                      //@ts-ignore
                      Math.abs(warrantydate - new Date()) /
                        (1000 * 60 * 60 * 24)
                    );
                  }
                  data[i].modified_date = new Date(
                    data[i].modified_date
                  ).toLocaleString();
                  this.warrantyData.push({
                    no: i + 1,
                    device_warranty_device_id: data[i].device_id,
                    device_warranty_modified_date: data[i].modified_date,
                    user_email: data[i].email,
                    remain_warranty: days,
                    //remain_warranty: this.lastProvidedMonth,
                    device_warranty_warranty: data[i].warranty
                  });
                }
                this.dataSource = new MatTableDataSource<WarrantyData>(
                  this.warrantyData
                );
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.AllData = this.warrantyData;
                this.totalPages = this.AllData.length / 10;
                this.totalPages = Math.ceil(this.totalPages);
                if (isPlatformBrowser(this.platformId)) {
                  if (window.screen.width < 1024) {
                    this.warrantyData = this.AllData.filter(
                      item => item.no < 11
                    );
                    window.document.getElementById(
                      "mobile-view"
                    ).style.display = "block";
                    window.document.getElementById(
                      "mobile-view"
                    ).style.width = window.screen.width.toString();
                    window.document.getElementById(
                      "warranty-details"
                    ).style.display = "none";
                  } else {
                    window.document.getElementById(
                      "warranty-details"
                    ).style.display = "block";
                    window.document.getElementById(
                      "mobile-view"
                    ).style.display = "none";
                  }
                }
              } else {
                this._translate.get("NoDeviceWarranty").subscribe(message => {
                  this._config.showMessage(message);
                });
              }
            } else {
              if (isPlatformBrowser(this.platformId)) {
                window.document.getElementById(
                  "warranty-details"
                ).style.display = "none";
              }
              //@ts-ignore
              this._config.showMessage(res.message);
            }
          },
          err => {
            this._dataExchangeService.changeProgress(false);
            if (isPlatformBrowser(this.platformId)) {
              window.document.getElementById("warranty-details").style.display =
                "none";
            }

            this._translate.get("NoResponseFound").subscribe(data => {
              this._config.showMessage(data);
            });
          }
        );
    }
  }

  /** It will change the page of cards for mobile viewer. */

  changePage(pageno) {
    delete this.warrantyData;
    this.warrantyData = new Array();
    let limit;
    if (pageno * 10 > this.AllData.length) {
      limit = 10 - (pageno * 10 - this.AllData.length);
    } else {
      limit = 10;
    }
    for (let i = 0; i < limit; i++) {
      this.warrantyData.push(this.AllData[pageno * 10 - 10 + i]);
    }
    this.currentPage = pageno;
  }

  /**
   * If user change the month, it will get warranty detail for that particular month.
   * @param no id of the input container of warranty
   */

  changeWarranty(no) {
    if (isPlatformBrowser(this.platformId)) {
      window.document.getElementById("updateBtn" + no).style.display = "block";
    }
  }
  /**
   * If user change the month, it will get warranty detail for that particular month for mobile.
   * @param no id of the input container of warranty
   */

  changeMobileWarranty(no) {
    if (isPlatformBrowser(this.platformId)) {
      window.document.getElementById("mobileBtn" + no).style.display = "block";
    }
  }

  /**
   * It will call service for updating the device warranty.
   * @param deviceData Oject of device warranty which user want to update
   */

  updateWarranty(deviceData) {
    let editData = {
      device_id: deviceData.device_warranty_device_id,
      warranty: deviceData.device_warranty_warranty
    };
    this._translate.get("AskForUpdateWarranty").subscribe(data => {
      const dialogRef = this.confirmDialog.open(ConfirmDialog, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._dataExchangeService.changeProgress(true);
          this._deviceService.updateWarranty(editData).subscribe(
            res => {
              //@ts-ignore
              if (res.status == 501) {
                this._translate.get("NoResponseFound").subscribe(data => {
                  this._config.showMessage(data);
                });
              } else {
                this._translate.get("SuccessWarrantyUpdate").subscribe(data => {
                  this._config.showMessage(data);
                });
              }
              this._dataExchangeService.changeProgress(false);
            },
            err => {
              this._translate.get("NoResponseFound").subscribe(data => {
                this._config.showMessage(data);
                this._dataExchangeService.changeProgress(false);
              });
            }
          );
        }
      });
    });
  }
}
@Component({
  selector: "dialog-overview-example-dialog",
  template: `
    <div>
      <div class="icon"><mat-icon class="info">help</mat-icon></div>
      <div class="main">
        <p>{{ data }}</p>
      </div>
    </div>
    <br /><br />
    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog(false)">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        (click)="closeDialog(true)"
        cdkFocusInitial
      >
        OK
      </button>
    </mat-dialog-actions>
  `,
  styleUrls: ["./ok-cancel.scss"]
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  closeDialog(result) {
    this.dialogRef.close(result);
  }
}
