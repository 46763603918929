import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class InteractionService {
  private lastMenu = new Subject<any>();
  private dashboard = new Subject<any>();

  lastMenu$ = this.lastMenu.asObservable();
  goToDashboard$ = this.dashboard.asObservable();

  constructor() {}

  changeMenu(menuid) {
    this.lastMenu.next(menuid);
  }

  goToDadshboard(data) {
    this.dashboard.next(data);
  }
}
